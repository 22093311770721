import { MSGS_GET_CHANNEL_V2 } from '../graphql/messages-queries';

export const SET_INIT_MESSAGES = 'SET_INIT_MESSAGES';
export const SET_UPDATE_STATUS = 'SET_UPDATE_STATUS';
export const SET_INCOMING_MESSAGE = 'SET_INCOMING_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const TYPING_START = 'TYPING_START';
export const TYPING_END = 'TYPING_END';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_OPEN_CHANNEL = 'SET_OPEN_CHANNEL';

export const WS_STATUS = {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    ERROR: 'ERROR',
};

export const updateStatus = (status) => ({
    type: SET_UPDATE_STATUS,
    payload: status,
});

export const receiveNotification = (unreadCount) => ({
    type: RECEIVE_NOTIFICATION,
    payload: unreadCount,
});

const retrySendMessage = (wsClient, message, maxAttempts = 8, retryDelay = 500) => {
    let attempts = 0;

    const attemptSend = () => {
        if (wsClient.status === WS_STATUS.CONNECTED) {
            wsClient.send(message);
        } else if (attempts < maxAttempts) {
            attempts++;
            setTimeout(attemptSend, retryDelay);
        } else {
            console.error('Failed to send message: WebSocket not open');
        }
    };

    attemptSend();
};

export const initWSConnect =
    () =>
    (dispatch, getState, { wsChatClient }) => {
        wsChatClient.connect();
    };

export const registerListener =
    (type, callback) =>
    (dispatch, getState, { wsChatClient }) => {
        wsChatClient.on(type, callback);
    };

export const removeListener =
    (type) =>
    (dispatch, getState, { wsChatClient }) => {
        wsChatClient.off(type);
    };

export const subsToGlobalChat =
    () =>
    (dispatch, getState, { wsChatClient }) => {
        const subscriptionMessage = {
            command: 'subscribe',
            identifier: JSON.stringify({ channel: 'UserChannel' }),
        };
        retrySendMessage(wsChatClient, subscriptionMessage);
    };

export const subsToChat =
    (sid) =>
    (dispatch, getState, { wsChatClient }) => {
        const subscriptionMessage = {
            command: 'subscribe',
            identifier: JSON.stringify({
                channel: 'ConversationChannel',
                last_sid: sid,
            }),
        };
        retrySendMessage(wsChatClient, subscriptionMessage);
    };

export const unsubsToChat =
    (sid) =>
    (dispatch, getState, { wsChatClient }) => {
        const subscriptionMessage = {
            command: 'unsubscribe',
            identifier: JSON.stringify({
                channel: 'ConversationChannel',
                last_sid: sid,
            }),
        };
        retrySendMessage(wsChatClient, subscriptionMessage);
    };

export const setChannels = (channels) => (dispatch) => {
    dispatch({ type: SET_CHANNELS, payload: channels });
};

export const setOpenChannel = (channel) => (dispatch) => {
    dispatch({ type: SET_OPEN_CHANNEL, payload: channel });
};

export const setIncomingMessage = (message) => (dispatch) => {
    dispatch({ type: SET_INCOMING_MESSAGE, payload: message });
};

export const setUnreadMessages = (unreadCount) => (dispatch) => {
    dispatch({ type: SET_UNREAD_MESSAGES, payload: unreadCount });
};

export const initChannel =
    (userId, spotId) =>
    async (dispatch, _getState, { apolloClient }) => {
        let res = false;
        try {
            const {
                errors,
                data: {
                    me: { channelV2 },
                },
            } = await apolloClient.query({
                variables: { userId, spotId, create: true },
                query: MSGS_GET_CHANNEL_V2,
            });

            if (!errors) {
                res = channelV2;
            } else {
                console.warn(errors);
            }
        } catch (e) {
            console.error(e);
        }
        return res;
    };

export const loadMoreMessages =
    (sid, anchor) =>
    (dispatch, getState, { wsChatClient }) => {
        const performMessage = {
            command: 'message',
            identifier: JSON.stringify({ channel: 'ConversationChannel', last_sid: sid }),
            data: JSON.stringify({ action: 'load_more', message_id: anchor }),
        };
        retrySendMessage(wsChatClient, performMessage);
    };

export const handleTypingStart =
    (sid) =>
    (dispatch, getState, { wsChatClient }) => {
        const performMessage = {
            command: 'message',
            identifier: JSON.stringify({ channel: 'ConversationChannel', last_sid: sid }),
            data: JSON.stringify({ action: 'typing_start' }),
        };
        retrySendMessage(wsChatClient, performMessage);
    };

export const handleTypingEnd =
    (sid) =>
    (dispatch, getState, { wsChatClient }) => {
        const performMessage = {
            command: 'message',
            identifier: JSON.stringify({ channel: 'ConversationChannel', last_sid: sid }),
            data: JSON.stringify({ action: 'typing_end' }),
        };
        retrySendMessage(wsChatClient, performMessage);
    };
