import { gql } from '@apollo/client';
import { MSGS_CHANNEL_FRAGMENT } from './messages-fragments';

export const MSGS_CHANNELS_QUERY = gql`
    query channelsList {
        messageToken
        channels {
            ...ChannelInfo
        }
    }
    ${MSGS_CHANNEL_FRAGMENT}
`;

export const MSGS_CREATE_CHANNEL = gql`
    query createChannelWith($id: ID!, $about: About) {
        channelWith(id: $id, about: $about) {
            ...ChannelInfo
        }
    }
    ${MSGS_CHANNEL_FRAGMENT}
`;

export const MSGS_GET_CHANNEL = gql`
    query channel($userId: ID!, $spotId: ID, $create: Boolean) {
        me {
            channel(userId: $userId, spotId: $spotId, create: $create) {
                sid
                token
            }
        }
    }
`;

export const MSGS_GET_CHANNEL_V2 = gql`
    query channelV2($userId: ID, $spotId: ID, $sid: String, $create: Boolean) {
        me {
            channelV2(userId: $userId, spotId: $spotId, sid: $sid, create: $create) {
                id
                sid
                interlocutor {
                    id
                    avatarAws
                    firstname
                    lastname
                }
                spot {
                    id
                    host {
                        id
                        firstname
                        lastname
                    }
                    title
                    rating
                    city
                    state
                    thumbPhoto
                    timezone
                }
            }
        }
    }
`;
