import { SET_CHANNELS, SET_INCOMING_MESSAGE, SET_OPEN_CHANNEL, SET_UNREAD_MESSAGES } from '../actions/chat.actions';

const initialState = {
    channels: null,
    incomingMessage: null,
    openChannel: null,
    unreadsCount: 0,
};

export const chat = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHANNELS:
            return {
                ...state,
                channels: action.payload,
            };
        case SET_OPEN_CHANNEL:
            return {
                ...state,
                openChannel: action.payload,
            };
        case SET_INCOMING_MESSAGE:
            return {
                ...state,
                incomingMessage: action.payload,
            };
        case SET_UNREAD_MESSAGES:
            return {
                ...state,
                unreadsCount: action.payload,
            };
        default:
            return state;
    }
};
